import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { EnvironmentService } from './environment.service';
import { environment } from 'src/environments/environment';
import { catchError, timeout } from 'rxjs/operators';
import { throwError } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PostStripeService {
  private _env: string = this.env._env;
  private url = null;

  constructor(private http: HttpClient, private env: EnvironmentService) {}

  public postContalinkStripe(postStripe: PostStripe): Observable<any> {
    
    //let headers: HttpHeaders = new HttpHeaders().set('X-Api-Key', this.api_token);
    let headers: HttpHeaders = new HttpHeaders({
      'Content-Type':  'application/json',
      'X-Api-Key': environment[this._env]['back_end_api_token']
    })

    this.url = `${environment[this._env]['back_end_url']}/poststripe`;

    return this.http.post<any>(this.url, postStripe, { headers: headers }).pipe(
      timeout(120000),
      catchError((error) => {
        if (error.name == "TimeoutError") {
          console.error('La solicitud ha excedido el tiempo de espera');
        }
        return throwError(error);
      })
    )
  }
}
export interface PostStripe {
    uc_customer_stripe_id: string
}